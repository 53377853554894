import Layout from 'customer/components/Layout/Layout';
import { AppRouteAction } from 'customer/types';
import LoginPage from 'src/customer/components/iam/Login/LoginPage';

const action: AppRouteAction = ({ query: { redirect_uri: redirectUri } }) => {
  return {
    chunks: ['login'],
    title: 'Login',
    protected: false,
    component: (
      <Layout>
        <LoginPage redirectUri={redirectUri} />
      </Layout>
    ),
  };
};

export default action;
